import { Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { authGuard } from './core/guards/auth.guard';
import { ResetPasswordComponent } from './modules/not-auth/authentication/reset-password/reset-password.component';
import { adminGuard } from './core/guards/admin.guard';
import { DynamicContentEmbeddedComponent } from './modules/auth/pages/dynamic-content-embedded/dynamic-content-embedded.component';
import { ProfileComponent } from './modules/auth/pages/profile/profile.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/not-auth/authentication/authentication.routes'),
  },
  {
    path: 'resetPassword/:token',
    component: ResetPasswordComponent,
  },
  {
    path: 'auth',
    component: LayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'admin',
        loadChildren: () =>
          import('./modules/system-admin/system-admin.routes'),
        canActivate: [adminGuard],
      },
      {
        path: 'admin-system',
        loadChildren: () =>
          import(
            './modules/system-admin/administrative-system/administrative-system.routes'
          ),
        canActivate: [adminGuard],
      },
      {
        path: 'dashboard',
        loadComponent: () =>
          import('./pages/dashboard/dashboard.component').then(
            x => x.DashboardComponent
          ),
      },
      {
        path: 'mandatory-reviews',
        loadChildren: () =>
          import(
            './modules/auth/pages/mandatory-reviews/mandatory-reviews.routes'
          ),
      },
      {
        path: 'periodic-reviews',
        loadChildren: () =>
          import(
            './modules/auth/pages/periodic-reviews/periodic-reviews.routes'
          ),
      },
      {
        path: 'history',
        loadChildren: () =>
          import('./modules/auth/pages/history/history.routes'),
      },
      {
        path: 'embedded-content',
        component: DynamicContentEmbeddedComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'admeasurement',
        loadChildren: () =>
          import(
            './modules/system-admin/admin-admeasurement/admeasurement.routes'
          ),
      },
      {
        path: 'resizing',
        loadChildren: () =>
          import('./modules/auth/pages/resizing/resizing.routes'),
      },
    ],
  },
];
