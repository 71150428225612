import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, HostListener, inject, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AuthService } from '../core/services/auth/auth.service';
import { AvatarComponent } from '../shared/components/avatar/avatar.component';
import { FooterComponent } from '../shared/components/footer/footer.component';
import { PermissoesService } from './../shared/services/permissions.service';
@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    FooterComponent,
    RouterOutlet,
    RouterLink,
    NgOptimizedImage,
    AvatarComponent,
    TranslateModule,
    CommonModule,
    MatTooltipModule,
    MatButtonModule,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.css',
})
export class LayoutComponent implements OnDestroy {
  name: string | null = sessionStorage.getItem('username');
  isLanguageDropdownVisible = false;
  isAvatarDropdownVisible = false;
  selectedLanguage = localStorage.getItem('language') || 'pt-br';
  isAdmin: boolean = false;

  languages = [
    { value: 'pt-br', label: 'Português', flag: 'assets/icons/bra.png' },
    { value: 'en', label: 'English', flag: 'assets/icons/usa.png' },
    { value: 'es', label: 'Español', flag: 'assets/icons/esp.png' },
  ];

  private translate = inject(TranslateService);

  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
    private router: Router,
    private readonly permissoesService: PermissoesService
  ) {
    this.translate.setDefaultLang(this.selectedLanguage);

    this.router.events.subscribe(() => {
      this.isAdmin = this.router.url.startsWith('/auth/admin');
    });
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  canViewAdmSystem(): boolean {
    const isAdmin = sessionStorage.getItem('admSystem');

    return isAdmin === 'true' ? true : false;
  }

  canAccessEntregaTecnica(): boolean {
    return this.permissoesService.hasPermission('entregaTecnica');
  }

  canAcessPeriodicReview(): boolean {
    return this.permissoesService.hasPermission('revisaoPeriodica');
  }

  canAcessMandatoryReview(): boolean {
    return this.permissoesService.hasPermission('revisaoObrigatoria');
  }

  canAcessAnalysisCommissioning(): boolean {
    return this.permissoesService.hasPermission('analise.entregaTecnica');
  }

  canAcessAdmeasurement(): boolean {
    return this.permissoesService.hasPermission('afericao.pivoCentral');
  }

  /**
   * Check if the user is a demo user
   * @returns {boolean} If the user is a demo user
   */
  isDemoUser(): boolean {
    return this.permissoesService.IsDemoUser(
      Number(sessionStorage.getItem('id_user'))
    );
  }

  toggleAvatarDropdown(event: MouseEvent): void {
    event.stopPropagation();
    this.isAvatarDropdownVisible = !this.isAvatarDropdownVisible;
    this.isLanguageDropdownVisible = false;
  }

  goToProfile(event: MouseEvent): void {
    event.stopPropagation();
    this.isAvatarDropdownVisible = false;
    this.router.navigate(['/auth/profile']);
  }
  goToAdmeasurement(event: MouseEvent): void {
    event.stopPropagation();
    this.isAvatarDropdownVisible = false;
    this.router.navigate(['/auth/admin']);
  }

  gotToAdmSystem(event: MouseEvent): void {
    event.stopPropagation();
    this.isAvatarDropdownVisible = false;
    if (this.canViewAdmSystem()) {
      this.openIframe('admsystem');
    }
    // this.router.navigate(['/auth/admin-system']);
  }

  toggleLanguageDropdown(event: MouseEvent): void {
    event.stopPropagation();
    this.isLanguageDropdownVisible = !this.isLanguageDropdownVisible;
    this.isAvatarDropdownVisible = false;
  }

  changeLanguage(language: string, event: MouseEvent): void {
    event.stopPropagation();
    this.selectedLanguage = language;
    this.translate.use(language);
    localStorage.setItem('language', language);
    this.isLanguageDropdownVisible = false;
    window.location.reload();
  }

  openIframe(page: string): void {
    const token = sessionStorage.getItem('auth-token');
    if (token) {
      this.router.navigate(['/auth/embedded-content'], {
        queryParams: { token, page },
      });
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const dropdownElement = document.querySelector('.relative') as HTMLElement;

    if (dropdownElement && !dropdownElement.contains(target)) {
      this.isLanguageDropdownVisible = false;
      this.isAvatarDropdownVisible = false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
